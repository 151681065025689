import { MouseEventHandler, PropsWithChildren } from 'react';

import { TVStyleProps } from '../../style-system';
import { LinkOrButton } from '../link-or-button';

import { tagStyles } from './tag.styles';

type TagProps = PropsWithChildren<
    TVStyleProps<typeof tagStyles> & {
        href?: string;
        onClick?: MouseEventHandler<HTMLButtonElement>;
    }
>;

const Tag = ({ children, variant = 'default', href, onClick, iconOnly, disableClick }: TagProps) => {
    const styles = tagStyles({ variant, iconOnly, disableClick });

    if (href || onClick) {
        return (
            <LinkOrButton href={href} onClick={onClick} className={styles}>
                {children}
            </LinkOrButton>
        );
    }

    return <div className={styles}>{children}</div>;
};

export type { TagProps };
export { Tag };
