import { startCase } from 'lodash-es';
import { SessionUser } from 'next-auth';

type AnalyticsUser = {
    [key: string]: string;
    $email: string;
    $name: string;
};
export const mapUserToAnalyticsUser = (user: SessionUser): AnalyticsUser => {
    const analyticsUser: AnalyticsUser = {
        $email: user.email,
        $name: `${user.firstname} ${user.lastname}`,
    };

    const keys = Object.keys(user).filter(key => key !== 'email');
    keys.forEach(key => {
        if (key === 'firstname') key = 'first name';
        if (key === 'lastname') key = 'last name';
        analyticsUser[startCase(key)] = user[key as keyof SessionUser];
    });

    return analyticsUser;
};
