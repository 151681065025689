import * as React from 'react';

import { Avatar, Badge, FlexBox, List, Text } from '@blockworks/ui/components';

import { AppRoutes } from '@/api/auth/auth.routes';
import { getAssetLogo } from '@/utils/logos';

import { ItemTypes } from '../utils/search.enums';
import { ProposalHit, SearchHitProps } from '../utils/search.types';

const ProposalItem = (props: SearchHitProps<ProposalHit>) => {
    const { id, objectID, title, status, image, assets, onSelect } = props;
    const itemData = { id, objectID, title, status, image, assets } as ProposalHit;
    const imageSrc = image ?? getAssetLogo(assets?.code || '');
    const assetSlug = assets?.code ? assets.code.toLowerCase() : '';
    const href = AppRoutes.ASSETS_SLUG_GOVERNANCE_PROPOSAL_ID.pathname(assetSlug, id);

    return (
        <List.Row id={objectID} onClick={() => onSelect?.(itemData, ItemTypes.Proposal)} href={href}>
            <List.Col size="content">{image && <Avatar alt={title} src={imageSrc} />}</List.Col>
            <List.Col size="xl" grow>
                <FlexBox>
                    <Text size="sm" weight="semibold">
                        {title}
                    </Text>
                </FlexBox>
            </List.Col>
            <List.Col size="content">{status && <Badge key={status}>{status}</Badge>}</List.Col>
        </List.Row>
    );
};

export { ProposalItem };
